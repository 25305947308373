<template>
  <header class="page-header">
    <div class="header-content">
      <h2 class="main-title">
        <a href="https://reddinfo.com" class="main-link">Reddinfo</a>
      </h2>
      <p class="tagline">Fast, Clear Reddit Insights <br/> Without the Clutter</p>
    </div>
  </header>
</template>

<script>
export default {
  name: "PageHeader",
  data() {
    return {
      email: "info@reddinfo.com",
      emailCopied: false,
    };
  },
  methods: {
    async copyEmail() {
      try {
        await navigator.clipboard.writeText(this.email);
        this.emailCopied = true;
        setTimeout(() => {
          this.emailCopied = false;
        }, 2000); // Reset after 2 seconds
      } catch (error) {
        console.error("Failed to copy email", error);
      }
    },
  },
};
</script>

<style scoped>
/* Header Styles */
.page-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 40px 20px;
  position: relative;
}

/* Main Title & Tagline */
.main-title {
  font-size: 3rem;
  font-weight: bold;
  margin: 0;
  color: #333;
}

.tagline {
  font-size: 1.2rem;
  color: #666;
  margin-top: 10px;
}
.main-link {
  text-decoration: none; /* Remove underline */
  color: inherit;        /* Inherit the color from the parent heading */
}

.main-link:hover {
  text-decoration: none; /* Add underline on hover for a clickable effect */
}
</style>