<template>
  <div v-if="postsEngagement" class="text-center">
    <!-- Highlighted Average Engagement Card -->
    <div class="card mb-3 p-4 text-white" style="background: #007bff;">
      <div class="card-body">
        <h4 class="card-title">Average Engagements Per Post</h4>
        <h1 class="display-2">{{ postsEngagement.averageEngagement }}</h1>
      </div>
    </div>

    <!-- Supporting Stats Row -->
    <div class="row">
      <div class="col-md-6">
        <div class="card">
          <div class="card-body text-center">
            <h5 class="text-muted">Average Upvotes</h5>
            <h3 class="display-5">{{ postsEngagement.averageUps }}</h3>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card">
          <div class="card-body text-center">
            <h5 class="text-muted">Average Comments</h5>
            <h3 class="display-5">{{ postsEngagement.averageComments }}</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


  <script>
  export default {
    name: 'postsEngagement',
    props: ['postsEngagement'], // Accept subredditDetails as a prop
  };
  </script>