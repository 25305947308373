<template>
  <div v-if="postsPerDay" class="container pt-5 d-flex flex-column justify-content-center align-items-center">
    <!-- Title or Description Text -->
    <p class="text-center text-muted">Subreddit Activity: Last 100 Posts</p>
    
    <!-- ApexCharts Area Chart -->
    <div class="col-12 col-md-8">
      <apexchart 
        type="area" 
        height="350" 
        :options="chartOptions" 
        :series="chartSeries" 
      />
    </div>
  </div>
  <div v-else>
  </div>
</template>

<script>
import VueApexCharts from "vue3-apexcharts";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
dayjs.extend(advancedFormat);

export default {
  components: { apexchart: VueApexCharts },
  name: "postsPerDay",
  props: {
    postsPerDay: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      chartOptions: {
        chart: {
          id: "posts-per-day",
          toolbar: {
            show: true,  // Enable/Disable toolbar
            tools: {
              zoom: false,         // Disables zooming (no zoom-in or zoom-out)
              pan: false,          // Disables panning (no dragging the chart)
              reset: false,        // Disables the reset zoom button
              selection: false,    // Disables the selection tool
              zoomin: false,       // Disables the zoom-in button
              zoomout: false,      // Disables the zoom-out button
              customIcons: false,  // Disables custom icons
            },
          },
        },
        xaxis: {
          type: "datetime",
          labels: {
            formatter: function(value) {
              return dayjs(value).format("DD MMM");
            },
            rotate: -45,
          },
        },
        yaxis: {
          title: {
            text: "Number of Posts",
            style: {
              fontSize: "14px",
            },
          },
        },
        tooltip: {
          x: {
            formatter: function(val) {
              return dayjs(val).format("DD MMM YYYY HH:mm");
            },
          },
        },
      },
      chartSeries: [
        {
          name: "Posts",
          data: [],  // Holds the actual data for the posts
        },
      ],
    };
  },
  watch: {
    postsPerDay(newData) {
      if (newData) {
        const formattedData = newData.map((d) => ({
          x: d.timestamp,  // Time-based data
          y: d.count || 0, // Number of posts
        }));
    
        // Update chart series with formatted data
        this.chartSeries[0].data = formattedData;
      }
    },
  },
};
</script>