import { createRouter, createWebHistory } from 'vue-router';
import { nextTick } from 'vue'; // Import nextTick to wait for the component to be fully mounted
import { useHead } from '@vueuse/head'; // Import useHead to update meta tags
import App from './App.vue';

const routes = [
  {
    path: '/analyze-profile/:username?',
    name: 'AnalyzeProfile',
    component: App, // Or a dedicated component for Analyze Profile
  },
  {
    path: '/subreddit-stats/:subreddit?',
    name: 'SubredditStats',
    component: App,
  },
  {
    path: '/explore-subreddits/:keyword?',
    name: 'ExploreSubreddits',
    component: App,
  },
  {
    path: '/',
    redirect: '/analyze-profile',
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// Dynamic meta tags update using beforeEach
router.beforeEach((to, from, next) => {
  if (to.name === 'AnalyzeProfile') {
    const username = to.params.username || ''; // Get the dynamic username parameter

    nextTick(() => {
      // Use useHead to update meta tags dynamically after route has resolved
      useHead({
        title: `${username ? `${username}'s Reddit Profile Stats` : 'Analyze Profile'} - Reddinfo`, // Set title dynamically
        meta: [
          {
            name: 'description',
            content: `Discover detailed statistics and activity for Reddit user ${username ? username : 'profile'} on Reddinfo.`,
          },
          {
            property: 'og:title',
            content: `${username ? `${username}'s Reddit Profile Stats` : 'Analyze Profile'} - Reddinfo`,
          },
          {
            property: 'og:description',
            content: `Get in-depth insights for Reddit user ${username ? username : 'profile'} on Reddinfo.`,
          },
          {
            property: 'og:url',
            content: `https://reddinfo.com/analyze-profile/${username}`,
          },
        ],
      });
    });
  } else if (to.name === 'SubredditStats') {
    const subreddit = to.params.subreddit || ''; // Get the dynamic subreddit parameter

    nextTick(() => {
      // Use useHead to update meta tags dynamically after route has resolved
      useHead({
        title: `${subreddit ? `${subreddit}'s Subreddit Stats` : 'Subreddit Stats'} - Reddinfo`, // Set title dynamically
        meta: [
          {
            name: 'description',
            content: `Get detailed statistics and insights for the subreddit ${subreddit ? subreddit : 'subreddit'} on Reddinfo.`,
          },
          {
            property: 'og:title',
            content: `${subreddit ? `${subreddit}'s Subreddit Stats` : 'Subreddit Stats'} - Reddinfo`,
          },
          {
            property: 'og:description',
            content: `Discover in-depth data and engagement insights for the subreddit ${subreddit ? subreddit : 'subreddit'} on Reddinfo.`,
          },
          {
            property: 'og:url',
            content: `https://reddinfo.com/subreddit-stats/${subreddit}`,
          },
        ],
      });
    });
  } else if (to.name === 'ExploreSubreddits') {
    const keyword = to.params.keyword || ''; // Get the dynamic keyword parameter

    nextTick(() => {
      // Use useHead to update meta tags dynamically after route has resolved
      useHead({
        title: `${keyword ? `Explore Subreddits About ${keyword}` : 'Explore Subreddits'} - Reddinfo`, // Set title dynamically
        meta: [
          {
            name: 'description',
            content: `Discover subreddits related to ${keyword ? keyword : 'your interest'} and explore detailed insights on Reddinfo.`,
          },
          {
            property: 'og:title',
            content: `${keyword ? `Explore Subreddits About ${keyword}` : 'Explore Subreddits'} - Reddinfo`,
          },
          {
            property: 'og:description',
            content: `Search for subreddits related to ${keyword ? keyword : 'your keyword'} and gain detailed insights on Reddinfo.`,
          },
          {
            property: 'og:url',
            content: `https://reddinfo.com/explore-subreddits/${keyword}`,
          },
        ],
      });
    });
  }

  next(); // Ensure the navigation proceeds
});

export default router;