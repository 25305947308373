/* global gtag */
export async function exploreSubreddits(keyword) {
    try {
    // Google Analytics Event Tracking
    if (typeof gtag === 'function') {
      gtag('event', 'explored_subreddit', {
        search_term: keyword,
        event_category: 'engagement',
        event_label: 'Explored Subreddit Triggered',
        value: 1,
      });
    } 
      const response = await fetch(`https://www.reddit.com/subreddits/search.json?q=${keyword}&limit=12`);
      const data = await response.json();
  
      // Extract relevant subreddit details and format the subscribers
      const exploredSubreddits = data.data.children.map(item => ({
        displayName: item.data.display_name,
        title: item.data.title,
        description: item.data.public_description || 'No description available.',
        subscribers: item.data.subscribers ? new Intl.NumberFormat().format(item.data.subscribers) : '0',  // Format subscribers with commas
        icon: item.data.icon_img || 'N/A',
        url: item.data.url
      }));
  
      // Sort the subreddits by subscribers in descending order
      exploredSubreddits.sort((a, b) => {
        return b.subscribers.replace(/,/g, '') - a.subscribers.replace(/,/g, '');  // Sorting as numbers
      });
      return exploredSubreddits;  // Return sorted subreddits data
    } catch (error) {
      console.error('Error fetching subreddits:', error);
      return [];  // Return an empty array if an error occurs
    }
}