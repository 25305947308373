<template>
    <div ref="wordCloudContainer" class="mt-4 wordcloud-container">
      <h3 class="">Keyword Usage Analysis</h3>
  
      <div ref="tooltip" class="tooltip" v-show="tooltipVisible" :style="tooltipStyles">
        {{ tooltipContent }}
      </div>
  
      <svg
        :width="svgWidth"
        :height="svgHeight"
        :viewBox="`0 0 ${svgWidth} ${svgHeight}`"
        preserveAspectRatio="xMidYMid meet"
      >
        <g :transform="`translate(${svgWidth / 2}, ${svgHeight / 2})`">
          <g
            v-for="word in words"
            :key="word.text"
            :transform="`translate(${word.x}, ${word.y})`"
          >
            <text
              :fill="randomColor()"
              @click="wordClick(word.text, word.size)"
              @mouseover="showTooltip(word.text, word.size, $event)"
              @mouseout="hideTooltip"
              :style="{ fontSize: word.size + 'px' }"
              text-anchor="middle"
            >
              {{ word.text }}
            </text>
          </g>
        </g>
      </svg>
    </div>
  </template>

<script>
import { ref, onMounted } from "vue";
import cloud from "d3-cloud";

export default {
  name: "WordCloud",
  props: {
    noob: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const wordCloudContainer = ref(null); // Reference to the parent container
    const svgWidth = ref(100); // Initialize with a default value
    const svgHeight = ref(100); // Initialize with a default value (square aspect ratio)
    const words = ref([]);
    const tooltipVisible = ref(false);
    const tooltipContent = ref("");
    const tooltipStyles = ref({});

    const randomColor = () => {
      const letters = "0123456789ABCDEF";
      let color = "#";
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    };

    const generateCloud = () => {
      if (!svgWidth.value || !svgHeight.value) return;

      const topWords = [...props.noob];
      topWords.sort((a, b) => b.weight - a.weight);
      const top30Words = topWords.slice(0, 30);

      const minWeight = Math.min(...top30Words.map((word) => word.weight));
      const maxWeight = Math.max(...top30Words.map((word) => word.weight));

      const normalize = (weight) => {
        const minSize = 10;
        const maxSize = 50;
        return (
          ((weight - minWeight) / (maxWeight - minWeight)) * (maxSize - minSize) +
          minSize
        );
      };

      const wordData = top30Words.map((word) => ({
        text: word.text,
        size: normalize(word.weight),
      }));

      cloud()
        .size([svgWidth.value, svgHeight.value])
        .words(wordData)
        .padding(5)
        .rotate(() => (Math.random() > 0.5 ? 0 : 90))
        .fontSize((d) => d.size)
        .on("end", (output) => {
          words.value.splice(0, words.value.length, ...output);
        })
        .start();
    };

    const wordClick = (text, size) => {
        console.log(`Word clicked: ${text} (size: ${size})`);
      };
  
    const showTooltip = (text, size, event) => {
    tooltipContent.value = `${text}: ${size}`;
    tooltipStyles.value = {
        position: "absolute",
        left: `${event.pageX + 10}px`,
        top: `${event.pageY + 10}px`,
        background: "#333",
        color: "#fff",
        padding: "5px",
        borderRadius: "5px",
        zIndex: 1000,
    };
    tooltipVisible.value = true;
    };

    const hideTooltip = () => {
    tooltipVisible.value = false;
    };

    const updateDimensions = () => {
      const container = wordCloudContainer.value;
      if (container) {
        const containerWidth = container.offsetWidth;
        svgWidth.value = containerWidth;
        svgHeight.value = containerWidth; // Keep height equal to width for square aspect ratio
        generateCloud();
      }
    };

    onMounted(() => {
      updateDimensions(); // Calculate dimensions on mount
      window.addEventListener("resize", updateDimensions); // Update on window resize
    });

    

    return {
      wordCloudContainer,
      svgWidth,
      svgHeight,
      words,
      randomColor,
      wordClick,
      tooltipVisible,
      tooltipContent,
      tooltipStyles,
      showTooltip,
      hideTooltip,
    };
    
  },
};
</script>

<style scoped>
.wordcloud-container {
  padding: 20px;
  background: #f8f9fa;
  position: relative;
}

svg {
  width: 100%;
  height: 100%;
}

.tooltip {
  pointer-events: none; /* Prevent tooltip from interfering with mouse events */
}
</style>




<!-- <template>
    <div ref="wordCloudContainer" class="mt-4 wordcloud-container">
        <h3 class="">Keyword Usage Analysis</h3>
  
      <svg
        :width="svgWidth"
        :height="svgHeight"
        :viewBox="`0 0 ${svgWidth} ${svgHeight}`"
        preserveAspectRatio="xMidYMid meet"
      >
        <g :transform="`translate(${svgWidth / 2}, ${svgHeight / 2})`">
          <g
            v-for="word in words"
            :key="word.text"
            :transform="`translate(${word.x}, ${word.y})`"
          >
            <text
              :fill="randomColor()"
              @click="wordClick(word.text, word.size)"
              :style="{ fontSize: word.size + 'px' }"
              text-anchor="middle"
            >
              {{ word.text }}
            </text>
          </g>
        </g>
      </svg>
    </div>
  </template>
  
  <script>
  import { ref, onMounted } from "vue";
  import cloud from "d3-cloud";
  
  export default {
    name: "WordCloud",
    props: {
      noob: {
        type: Array,
        default: () => [],
      },
    },
    setup(props) {
      const wordCloudContainer = ref(null); // Reference to the parent container
      const svgWidth = ref(100); // Initialize with a default value
      const svgHeight = ref(100); // Initialize with a default value (square aspect ratio)
      const words = ref([]);
  
      const randomColor = () => {
        const letters = "0123456789ABCDEF";
        let color = "#";
        for (let i = 0; i < 6; i++) {
          color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
      };
  
      const generateCloud = () => {
        if (!svgWidth.value || !svgHeight.value) return;
  
        const topWords = [...props.noob];
        topWords.sort((a, b) => b.weight - a.weight);
        const top30Words = topWords.slice(0, 30);
  
        const minWeight = Math.min(...top30Words.map((word) => word.weight));
        const maxWeight = Math.max(...top30Words.map((word) => word.weight));
  
        const normalize = (weight) => {
          const minSize = 10;
          const maxSize = 50;
          return (
            ((weight - minWeight) / (maxWeight - minWeight)) * (maxSize - minSize) +
            minSize
          );
        };
  
        const wordData = top30Words.map((word) => ({
          text: word.text,
          size: normalize(word.weight),
        }));
  
        cloud()
          .size([svgWidth.value, svgHeight.value])
          .words(wordData)
          .padding(5)
          .rotate(() => (Math.random() > 0.5 ? 0 : 90))
          .fontSize((d) => d.size)
          .on("end", (output) => {
            words.value.splice(0, words.value.length, ...output);
          })
          .start();
      };
  
      const wordClick = (text, size) => {
        console.log(`Word clicked: ${text} (size: ${size})`);
      };
  
      const updateDimensions = () => {
        const container = wordCloudContainer.value;
        if (container) {
          const containerWidth = container.offsetWidth;
          svgWidth.value = containerWidth;
          svgHeight.value = containerWidth; // Keep height equal to width for square aspect ratio
          generateCloud();
        }
      };
  
      onMounted(() => {
        updateDimensions(); // Calculate dimensions on mount
        window.addEventListener("resize", updateDimensions); // Update on window resize
      });
  
      return {
        wordCloudContainer,
        svgWidth,
        svgHeight,
        words,
        randomColor,
        wordClick,
      };
    },
  };
  </script>
  
  <style scoped>
  .wordcloud-container {
    padding: 20px;
    background: #f8f9fa;

  }
  
  svg {
    width: 100%;
    height: 100%;
  }
  
  </style> -->