<template>
  <div id="app">
    <!-- Floating Widget for Desktop -->
    <div 
      v-if="Object.keys(results).length > 0" 
      class="position-fixed d-none d-md-block top-50 end-0 translate-middle-y px-3"
      id="desktop-widget"
    >
      <a href="https://www.buymeacoffee.com/heyumarkhan" target="_blank">
        <img 
          src="https://img.buymeacoffee.com/button-api/?text=Buy me a coffee&emoji=☕&slug=heyumarkhan&button_colour=0d6efd&font_colour=ffffff&font_family=Lato&outline_colour=000000&coffee_colour=ffffff" 
          alt="Buy Me a Coffee" 
        />
      </a>
    </div>

    <!-- Floating Widget for Mobile -->
    <div 
      v-if="Object.keys(results).length > 0" 
      class="position-fixed d-md-none bottom-0 start-50 translate-middle-x px-3"
      style="z-index: 1050;"
      id="mobile-widget"
    >
      <a href="https://www.buymeacoffee.com/heyumarkhan" target="_blank">
        <img 
          src="https://img.buymeacoffee.com/button-api/?text=Buy me a coffee&emoji=☕&slug=heyumarkhan&button_colour=FFDD00&font_colour=000000&font_family=Lato&outline_colour=000000&coffee_colour=ffffff" 
          alt="Buy Me a Coffee" 
          class="w-100"
        />
      </a>
    </div>
    
    <!-- Header Section -->
    <PageHeader />

    <!-- Tabs Section -->
    <div class="tabs-area">
      <div class="container">
        <div class="row justify-content-center text-center">
          <div class="col-12 col-md-10 col-lg-8">
            <button
              v-for="tab in tabs"
              :key="tab.id"
              :class="['tab-button', { active: activeTabID === tab.id }]"
              @click="changeTab(tab.id)"
            >
              {{ tab.name }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Search Input -->
    <div class="search-area">
      <input
        type="text"
        :value="searchQuery"
        @input="searchQuery = $event.target.value"
        placeholder="Enter search term"
        @keyup.enter="performSearch"
      />
      <button @click="performSearch">Search</button>
    </div>

    <!-- Loading Indicator -->
    <div v-if="isLoading" class="loading-indicator">Analyzing, please wait...</div>

    <!-- Results Section -->
    <div class="results-area" v-else>
      <!-- Subreddit Stats Tab -->
      <div>
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-8">
             <!-- Conditionally render each tab's content -->
              <UserProfileTab v-if="activeTabID === 'user-profiles'" :results="results" />
              <SubredditStatsTab v-if="activeTabID === 'subreddit-stats'" :results="results" />
              <ExploreSubredditsTab v-if="activeTabID === 'explore-subreddits'" :results="results" />
            </div>
          </div>
        </div>
      </div>

      <!-- Footer -->
      <AppFooter />
    </div>
  </div>
</template>

<script>
import PageHeader from './components/PageHeader.vue';
import AppFooter from './components/AppFooter.vue';
import UserProfileTab from './components/userProfiles/UserProfileTab.vue';
import SubredditStatsTab from './components/subreddit/SubredditStatsTab.vue';
import ExploreSubredditsTab from './components/explore/ExploreSubredditsTab.vue';
import appLogic from './logic/appLogic';

export default {
  name: 'App',
  components: {
    PageHeader,
    AppFooter,
    UserProfileTab,
    SubredditStatsTab,
    ExploreSubredditsTab,
  },
  data() {
    return {
      tabs: [
        { id: 'user-profiles', name: 'Analyze Profiles' },
        { id: 'subreddit-stats', name: 'Subreddit Stats' },
        { id: 'explore-subreddits', name: 'Explore Subreddits' },
      ],
      activeTabID: 'user-profiles', // Default tab
      searchQuery: '',
      results: {}, // Holds fetched data for the active tab
      isLoading: false,  // Track loading state
      a: [],
    };
  },
  watch: {
  $route(to) {
    // Map the route name to the active tab
    const tabMap = {
      AnalyzeProfile: 'user-profiles',
      SubredditStats: 'subreddit-stats',
      ExploreSubreddits: 'explore-subreddits',
    };
    this.activeTabID = tabMap[to.name] || 'user-profiles';

    // Extract search query from URL
    if (to.params.username) {
      this.searchQuery = to.params.username;
    } else if (to.params.subreddit) {
      this.searchQuery = to.params.subreddit;
    } else if (to.params.keyword) {
      this.searchQuery = to.params.keyword;
    } else {
      this.searchQuery = ''; // Reset if no query exists
    }

    // Automatically perform search if query exists
    if (this.searchQuery.trim()) {
      this.performSearch();
    }
  },
},
methods: {
  changeTab(tabID) {
    this.activeTabID = tabID;
    this.searchQuery = ''; // Clear search query on tab switch
    this.results = {}; // Clear previous results

    // Update the URL
    const pathMap = {
      'user-profiles': '/analyze-profile',
      'subreddit-stats': '/subreddit-stats',
      'explore-subreddits': '/explore-subreddits',
    };

    if (this.searchQuery) {
      // If there's a search query, add it to the URL
      console.log("Updating URL with search query: " + this.searchQuery);
      this.$router.push({ path: `${pathMap[tabID]}/${this.searchQuery}` });
    } else {
      this.$router.push({ path: pathMap[tabID] });
    }
  },

  async performSearch() {
  if (!this.searchQuery.trim()) {
    alert('Please enter a search term.');
    return;
  }

  // Update the URL based on the active tab and search query
  const pathMap = {
    'user-profiles': `/analyze-profile/${this.searchQuery}`,
    'subreddit-stats': `/subreddit-stats/${this.searchQuery}`,
    'explore-subreddits': `/explore-subreddits/${this.searchQuery}`,
  };
  
  if (this.$route.path !== pathMap[this.activeTabID]) {
    this.$router.push({ path: pathMap[this.activeTabID] });
  }

  this.isLoading = true;
  this.results = {};

  try {
    const payload = {
      tabID: this.activeTabID,
      query: this.searchQuery,
    };
    const fetchedData = await appLogic.fetchDataForTab(payload);
    this.results = fetchedData || {};
  } catch (error) {
    console.error('Error performing search:', error);
  } finally {
    this.isLoading = false;
  }
},
},

};
</script>

<style scoped>
/* Tab Button Styling */
.tabs-area {
  display: flex;
  flex-direction: row;
}

.tab-button {
  padding: 10px 15px;
  text-align: left;
  border: none;
  background: none;
  font-size: 16px;
  font-weight: normal;
  color: #333;
  border-bottom: 2px solid #dadadb;
  cursor: pointer;
}

.tab-button.active {
  color: #007bff;
  border-bottom: 2px solid #007bff;
}

/* Search Input Area Styling */
.search-area {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.search-area input {
  padding: 10px;
  font-size: 16px;
  width: 50%;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.search-area button {
  margin-left: 10px;
  padding: 10px 15px;
  font-size: 16px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
}
.results-area {
  margin: 20px;
}
.loading-indicator {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: #007bff;
  margin-top: 20px;
  animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
