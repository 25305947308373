export async function getPostsAnalytics(subreddit) {
    try {
      const url = `https://www.reddit.com/r/${subreddit}/new.json?limit=100`;
      const response = await fetch(url);
      const data = await response.json();
  
        // Check if the data and children exist
        if (!data || !data.data || !data.data.children) {
          console.error("Error: No valid data found for subreddit:", subreddit);
          return null;  // Return null or handle error as appropriate
      }
      
      //--------------- For Posts Engagement Stats ------------ 

        // Arrays to store values of all posts
        let engagements = [];
        let totalUps = 0;
        let totalComments = 0;
        let totalAwards = 0;

        // Loop through all posts in the fetched data
        data.data.children.forEach(post => {
          const { all_awardings, ups, num_comments } = post.data; 
          
          // Count awards, upvotes, and comments
          const awards = all_awardings.length;
          totalUps += ups;
          totalComments += num_comments;
          totalAwards += awards;

          // Calculate engagement using the formula
          const engagement = ((awards * 4) + ups + num_comments);
          engagements.push(engagement);
        });

        // Calculate averages
        const totalPosts = data.data.children.length;

        const averageEngagement = Math.round(
          engagements.reduce((total, engagement) => total + engagement, 0) / totalPosts
        );

        const averageUps = Math.round(totalUps / totalPosts);
        const averageComments = Math.round(totalComments / totalPosts);
        const averageAwards = Math.round(totalAwards / totalPosts);




      // ----------------------- posts per day ------------------------

      const posts = data.data.children.map(post => ({
        timestamp: post.data.created_utc * 1000, // Convert to milliseconds
        date: new Date(post.data.created_utc * 1000).toISOString().split("T")[0],
      }));
  
      // Group posts by day or hour
      const postsPerDay = posts.reduce((acc, post) => {
        const date = post.date;
        const hour = new Date(post.timestamp).getHours();
        const label = posts.length <= 48 
          ? `${date} ${hour}:00`  // Format for hourly data
          : date;  // For longer time ranges, just use the date
  
        acc[label] = (acc[label] || 0) + 1;
        return acc;
      }, {});
  

    // Format the data for chart usage
    const formattedData = Object.entries(postsPerDay).map(([label, count]) => {
      const dateObj = new Date(label.split(" ")[0]);
      return {
        date: label.split(" ")[0],
        timestamp: dateObj.getTime(),
        label,  // Will either be "2024-12-10" or "2024-12-10 12:00"
        count,
      };
    });

    return { formattedData, averageEngagement, averageUps, averageComments, averageAwards };
    } catch (error) {
      console.error("Error fetching posts per day:", error);
      return [];
    }
  }