<template>
    <div v-if="results.subreddits" class="explore-subreddits-tab">
      <ExploreSubreddits v-if="results.subreddits" :ExploreSubreddits="results.subreddits" />
      <!-- <div class="separator">Popular Subreddits</div>
      <PopSubreddits v-if="results.popSubreddits" :PopSubreddits="results.popSubreddits" /> -->
    </div>
  </template>
  
  <script>
  import ExploreSubreddits from './ExploreSubreddits.vue';
  // import PopSubreddits from './PopularSubreddits.vue';
  
  export default {
    name: 'ExploreSubredditsTab',
    components: {
      ExploreSubreddits,
      // PopSubreddits,
    },
    props: ['results'],
  };
  </script>
  
  <style scoped>
    .separator {
      display: flex;
      align-items: center;
      text-align: center;
      margin-top: 2rem;
      margin-bottom: 2rem;
      font-size: 18px;
      font-weight: bold;
      color: #333;
    }

    .separator::before,
    .separator::after {
      content: "";
      flex: 1;
      border-bottom: 2px solid #ddd;
      margin: 0 10px;
    }
  </style>