<template>
  <div v-if="userStats" class="row justify-content-center align-items-center text-center user-profile-stats">
    <div class="col-12 col-md-6">
      <!-- <h3>User Statistics</h3> -->
      <ul>
        <div v-if="userStats.icon" class="me-3">
          <img :src="userStats.icon" alt="Subreddit Icon" class="rounded-circle mb-4" width="70" height="70" />
        </div>
        <li><h4>u/{{ userStats.name }} </h4></li>
        <li class="mt-4"><strong>Account Age:</strong> {{ userStats.created }}</li>
        <li><strong>Total Karma:</strong> {{ userStats.karma }}</li>
        <li><strong>Posts Karma:</strong> {{ userStats.postsKarma }}</li>
        <li><strong>Comments Karma:</strong> {{ userStats.commentsKarma }}</li>
      </ul>
    </div>
  </div>
  <div v-else>
    <p class="text-center">This Reddit User Does Not Exist.</p>
  </div>
</template>
  
  <script>
  export default {
    name: "userStats",
    props: {
      userStats: {
        type: Object,
        required: true,
      },
    },
    filters: {
      number(value) {
        return new Intl.NumberFormat().format(value);
      },
    },
  };
  </script>
  
  <style scoped>
  .user-profile-stats {
    background: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    margin: 20px 0;
  }
  h3 {
    color: #333;
  }
  ul {
    list-style: none;
    padding: 0;
  }
  li {
    margin-bottom: 10px;
  }
  </style>