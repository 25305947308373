/* global gtag */
import { processText } from './subreddit/fetchSubPosts';
import { fetchSubDetails } from './subreddit/fetchSubAbout';
import { fetchSubRules } from './subreddit/fetchSubRules';
import { getPostsAnalytics } from './subreddit/newPosts';
import { fetchTopContributors } from './subreddit/fetchTopContributors';
import { exploreSubreddits } from './explore/exploreSubreddits';
import { fetchPopSubreddits } from './explore/popularSubreddits';
import { fetchUserStats } from './userProfiles/fetchUserStats';
import { fetchUserActivity, generateHeatmapData, processUserActivity } from './userProfiles/fetchUserActivity.js';

export default {
  /**
   * Centralized function to handle logic for multiple tabs.
   * @param {Object} param0 - Contains tabID and query
   * @param {string} param0.tabID - The selected tab ID
   * @param {string} param0.query - The search query input
   * @returns {Object|null} - Returns data corresponding to the tab or null if there is an error
   */
  async fetchDataForTab({ tabID, query }) {
    const trimmedInput = query.trim().split('/').pop(); // Get last part of the query

    // If input is empty, prompt the user
    if (!trimmedInput) {
      alert('Please enter a valid search input.');
      return null;
    }

    // Google Analytics Tracking
    this.trackSearchEvent(trimmedInput);

    try {
      // Handle logic for each tab
      switch (tabID) {
        case 'subreddit-stats':
          return await this.fetchSubredditStats(trimmedInput);

        case 'explore-subreddits':
          return await this.fetchExploreData(trimmedInput);

        case 'user-profiles':
          return await this.fetchUserProfile(trimmedInput);

        default:
          console.warn('Unknown tab selected');
          return null;
      }
    } catch (error) {
      console.error(`Error fetching data for ${tabID}:`, error);
      return null;
    }
  },

  /**
   * Fetches Subreddit Stats including details, engagement, rules, and contributors
   * @param {string} subreddit - Subreddit name
   * @returns {Object|null} - Subreddit stats data or null if error
   */
  async fetchSubredditStats(subreddit) {
    try {
      const data = {
        subredditDetails: await fetchSubDetails(subreddit),
        postsEngagement: await this.getEngagement(subreddit),
        subRules: await fetchSubRules(subreddit),
        fetchedPosts: await processText(subreddit),
        postsPerDay: await this.getPostsPerDay(subreddit),
        topContributors: await fetchTopContributors(subreddit),
      };
      return data;
    } catch (error) {
      console.error('Error fetching subreddit stats:', error);
      return null;
    }
  },

  /**
   * Fetches Explore Data such as related subreddits and popular subreddits
   * @param {string} keyword - Search keyword for subreddits
   * @returns {Object|null} - Explore data or null if error
   */
  async fetchExploreData(keyword) {
    try {
      const data = {
        subreddits: await exploreSubreddits(keyword),
        popSubreddits: await fetchPopSubreddits(),
      };
      return data;
    } catch (error) {
      console.error('Error exploring subreddits:', error);
      return null;
    }
  },

  /**
   * Fetches User Profile data such as stats, activity, and heatmap
   * @param {string} username - Reddit username
   * @returns {Object|null} - User profile data or null if error
   */
  async fetchUserProfile(username) {
    try {
      const userStats = await fetchUserStats(username);
      const { posts, comments } = await fetchUserActivity(username);

      // Generating Data for Heatmap
      const heatmapData = generateHeatmapData(posts, comments);

      if (posts || comments) {
        const userActivity = processUserActivity(posts, comments);
        return { userStats, userActivity, heatmapData };
      }
      return { userStats };
    } catch (error) {
      console.error('Error fetching user profile:', error);
      return null;
    }
  },

  /**
   * Fetches engagement analytics for a subreddit (upvotes, comments, etc.)
   * @param {string} subreddit - Subreddit name
   * @returns {Object|null} - Engagement data or null if error
   */
  async getEngagement(subreddit) {
    try {
      const { averageEngagement, averageUps, averageComments, averageAwards } = 
        await getPostsAnalytics(subreddit);
      return { averageEngagement, averageUps, averageComments, averageAwards };
    } catch (error) {
      console.error('Error fetching engagement stats:', error);
      return null;
    }
  },

  /**
   * Fetches posts per day for a subreddit (visualization data)
   * @param {string} subreddit - Subreddit name
   * @returns {Array} - Posts per day data or empty array if error
   */
  async getPostsPerDay(subreddit) {
    try {
      const { formattedData } = await getPostsAnalytics(subreddit);
      return formattedData || [];
    } catch (error) {
      console.error('Error fetching posts per day:', error);
      return [];
    }
  },

  /**
   * Tracks search events in Google Analytics
   * @param {string} searchTerm - The term that was searched for
   */
  trackSearchEvent(searchTerm) {
    if (typeof gtag === 'function') {
      gtag('event', 'search_triggered', {
        search_term: searchTerm,
        event_category: 'engagement',
        event_label: 'Search Triggered',
        value: 1,
      });
    }
  },
};