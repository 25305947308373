<template>
  <div class="container mt-4">
    <!-- Check if subRules data exists -->
    <div v-if="subRules">
      <!-- Display main subreddit rules -->
      <div v-if="subRules.subredditRules" class="subreddit-rules">
        <h2 class="text-center mt-5">Subreddit Rules</h2>
        <div class="accordion" id="rulesAccordion">
          <!-- Loop through the main rules and display each one -->
          <div v-for="(rule, index) in subRules.subredditRules" :key="rule.ruleNumber">
            <div class="accordion-item mb-2">
              <!-- Rule Title as Accordion Header -->
              <h2 class="accordion-header" :id="'heading' + index">
                <button class="accordion-button" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapse' + index" aria-expanded="false" :aria-controls="'collapse' + index">
                  <strong>{{ rule.title }}</strong>
                </button>
              </h2>
              <!-- Rule Description as Accordion Body -->
              <div :id="'collapse' + index" class="accordion-collapse collapse" :aria-labelledby="'heading' + index" data-bs-parent="#rulesAccordion">
                <div class="accordion-body">
                  <p>{{ rule.description }}</p>
                  <!-- Check if there are sub-points and display them -->
                  <ul v-if="rule.subPoints" class="list-unstyled ms-3">
                    <!-- Loop through sub-points and display each one -->
                    <li v-for="(subPoint, subIndex) in rule.subPoints" :key="subIndex">
                      - {{ subPoint }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'subRules',
  props: ['subRules'], // Accept formatted subRules as a prop
};
</script>