<template>
  <div v-if="posts" class="container mt-4">
    <h2 class="text-center mb-4">Top 5 Posts of the Month</h2>
    <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
      <div v-for="post in posts" :key="post.title" class="col">
        <div class="card shadow-sm border-light d-flex flex-column mb-3 p-3">
          <div class="card-body d-flex flex-column flex-grow-1">
            <h5 class="card-title">{{ post.title }}</h5>
            <!-- <p class="card-text">
              <span v-if="post.isExpanded" v-html="post.description"></span>
              <span v-else>{{ truncatedDescription(post.description) }}</span>
              <span v-if="post.description.length > 200" @click="toggleDescription(post)" class="text-primary">
                {{ post.isExpanded ? 'see less' : 'see more' }}
              </span>
            </p> -->
            <div class="text-center mb-3">
              <img v-if="post.media_url" :src="post.media_url" alt="Post Image" class="img-fluid rounded" />
              <img v-else-if="post.hasVideo" src="@/assets/images/video.png" alt="Video Icon" class="img-fluid" />
              <img v-else-if="post.hasGallery" src="@/assets/images/carousel.png" alt="Carousel Icon" class="img-fluid" />
            </div>
          </div>
          <div class="card-footer text-muted">
            <div class="d-flex justify-content-between">
              <p><strong>Upvotes:</strong> <span class="text-success">{{ post.score }}</span></p>
              <p><strong>Comments:</strong> <span class="text-danger">{{ post.comments }}</span></p>
            </div>
            <p><strong>Author:</strong> 
              <a :href="post.author_url" target="_blank" class="btn btn-link">
                {{ truncatedAuthorName(post.author) }}
              </a>
            </p>     
          </div>
          <a :href="post.post_url" target="_blank" class="btn btn-outline-primary mt-3">Visit Post</a>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.card {
  height: 100%;
}
</style>

<script>
export default {
  name: 'PostList',
  props: ['posts'], // Accept posts as a prop
  methods: {
    // Truncate description if it's longer than 200 characters
    truncatedDescription(description) {
      if (description && description.length > 200) {
        return description.substring(0, 200) + '...';
      }
      return description || '';  // Return an empty string if description is undefined
    },

    truncatedAuthorName(author) {
      if (author && author.length > 13) {
        return author.substring(0, 10) + '...'; // Truncate and add "..."
      }
      return author || ''; // Return an empty string if author is undefined or null
    },

    // Toggle the full description visibility
    toggleDescription(post) {
      post.isExpanded = !post.isExpanded;
    },
  },
  mounted() {
  if (!this.posts) {
    // Initialize each post to have 'isExpanded' set to false by default
    this.posts.forEach(post => {
      post.isExpanded = false;
    });
  }
}
};
</script>