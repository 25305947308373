export async function fetchSubRules(subName) {
  try {
    const response = await fetch(`https://www.reddit.com/r/${subName}/about/rules.json`);
    const data = await response.json();
    
    if (!data || !data.rules) {
      return null;
    }
    // Format the rules for easier display
    const formattedRules = data.rules.map((rule, index) => {
      const subPoints = rule.sub_rules
        ? rule.sub_rules.map((subRule, subIndex) => `${String.fromCharCode(97 + subIndex)}. ${subRule}`)
        : [];

      return {
        ruleNumber: index + 1,
        title: rule.short_name.replace(/&amp;/g, '&'),
        description: rule.description,
        subPoints,
      };
    });

    return {
      subredditRules: formattedRules,
      // No need for now
      // siteRules: data.site_rules || [], 
    };
  } catch (error) {
    console.error('Error fetching rules:', error);
    return null;
  }
}