<template>
  <div v-if="results.userStats && results.userStats.userFound" class=" row justify-content-center user-profile-tab">

    <userStats class=" col-12 col-md-10 col-lg-6" v-if="results.userStats" :userStats="results.userStats" />
    <UserActivityStats v-if="results.userActivity" :userActivity="results.userActivity" />
    <HeatmapData v-if="results.heatmapData" :heatmapData="results.heatmapData" />
    <WordCloud v-if="results.userActivity" :noob="results.userActivity.wordFrequency" />
  
    <div v-if="results.userStats">
      <div class="User-overview mt-5">
        <h3>Reddit User Overview: u/{{ results.userStats.name }}</h3>
        <p>
          With an account age of {{ results.userStats.name }}, u/{{ results.userStats.name }} has carved out their presence on Reddit by engaging with the community through {{ results.userActivity.totalPosts }} posts and {{ results.userActivity.totalComments }} comments. Their contributions have earned them a total karma of {{ results.userStats.karma }}, with {{ results.userStats.postsKarma }} from posts and {{ results.userStats.commentsKarma }} from comments.
        </p>
      </div>
      <div class="Activity-Highlights mt-4">
        <h3>Activity Highlights</h3>
        <div class="Top-Posting-Subreddits mt-3">
          <h5>Top Posting Subreddits:</h5>
          <p>
            u/{{ results.userStats.name }} has shared posts in subreddits like 
            <span v-for="(subreddit, index) in results.userActivity.topSubredditsPosts" :key="subreddit.subreddit">
              <strong>{{ subreddit.subreddit }}</strong> ({{ subreddit.count }} posts),
              <span v-if="index < results.userActivity.topSubredditsPosts.length - 1 && index < 6 ">, 
              </span>
            </span> contributing to discussions and sharing ideas on topics of interest.
          </p>
        </div>
        <div class="Top-Commenting-Subreddits mt-3">
          <h5>Top Commenting Subreddits:</h5>
          <p>

            Their comments are most frequent in communities such as 
            <span v-for="(subreddit, index) in results.userActivity.topSubredditsComments" :key="subreddit.subreddit">
              <strong>{{ subreddit.subreddit }}</strong> ({{ subreddit.count }} comments),
              <span v-if="index < results.userActivity.topSubredditsComments.length - 1 && index < 6 ">, 
              </span>
            </span>
          </p>
        </div>
      <div class="Reddit-Journey mt-4">
        <h3>Analyzing Their Reddit Journey</h3>
        <p>
          The User Activity Heatmap provides insights into u/{{ results.userStats.name }}’s most active days and hours, offering a deeper look into when they contribute most. Additionally, a Keyword Usage Analysis highlights frequently used terms.
        </p>
      </div>

      </div>
    </div>
  </div>
  <div v-else>
    <p class="text-center">This Reddit User Does Not Exist.</p>
  </div>
  <AnalyzeProfileFAQ class="mt-5" />
</template>

<script>
import { useHead } from '@vueuse/head';
import userStats from './userProfileStats.vue';
import UserActivityStats from './UserActivityStats.vue';
import HeatmapData from './activityHeatmap.vue';
import WordCloud from './wordsFrequencyChart.vue';
import AnalyzeProfileFAQ from './FAQs.vue';

export default {
  name: 'UserProfileTab',
  components: {
    userStats,
    UserActivityStats,
    HeatmapData,
    WordCloud,
    AnalyzeProfileFAQ,
  },
  props: ['results'],
  watch: {
    results: {
      handler() {
        this.setMetaTags();
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    setMetaTags() {
      if (this.results && this.results.userStats && this.results.userStats.username) {
        const username = this.results.userStats.username;

        useHead({
          title: `${username}'s Profile on Reddinfo`,
          meta: [
            {
              name: 'description',
              content: `Explore detailed analytics for Reddit user ${username} on Reddinfo. Discover their activity heatmap, word frequency, and engagement insights.`,
            },
            {
              property: 'og:title',
              content: `${username}'s Profile on Reddinfo`,
            },
            {
              property: 'og:description',
              content: `Discover in-depth statistics and activity trends for Reddit user ${username}.`,
            },
            {
              property: 'og:url',
              content: `https://reddinfo.com/analyze-profile/u/${username}`,
            },
          ],
        });
      }
    },
  },
};
</script>