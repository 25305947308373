<template>
  <div v-if="contributors" class="container mt-4">
    <h2 class="text-center mb-3">Top Posts Contributors</h2>
    <p class="text-muted text-center mb-4">Ranked based on account Karma</p>

    <div class="row justify-content-center">
      <div class="col-12 col-md-8">
        <div 
          v-for="contributor in contributors" 
          :key="contributor.username" 
          class="card mb-3 p-3"
        >
          <div class="d-flex flex-column flex-md-row justify-content-between">
            <p class="mb-1 align-self-center text-secondary">{{ contributor.username }}</p>
            <a 
              :href="contributor.profileLink" 
              target="_blank" 
              class="btn btn-outline-primary btn-sm mt-2 mt-md-0"
            >
              Visit Profile
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
props: {
  contributors: { // Accept contributors as a prop
    type: Array,
    required: true,
  },
},
};
</script>

<style scoped>
/* Add styles as needed */
</style>
