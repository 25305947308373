<template>
    <div v-if="results.subredditDetails && results.subredditDetails.subredditFound" class="subreddit-stats-tab">
      <SubAboutView v-if="results.subredditDetails" :subredditDetails="results.subredditDetails" />
      <AverageEngagement v-if="results.postsEngagement" :postsEngagement="results.postsEngagement" />
      <PostsPerDay v-if="results.postsPerDay" :postsPerDay="results.postsPerDay" />
      <TopContributors v-if="results.topContributors" :contributors="results.topContributors" />
      <PostsView v-if="results.fetchedPosts" :posts="results.fetchedPosts" />
      <SubRulesView v-if="results.subRules" :subRules="results.subRules" />

      <div>
        <div class="Subreddit-overview mt-5">
          <h3>{{ results.subredditDetails.name }} Subreddit Overview: </h3>
          <p>
            {{ results.subredditDetails.name }} is a subreddit on Reddit with {{ results.subredditDetails.subscribers }} members.
            On average, posts in this subreddit receives average {{ results.postsEngagement.averageEngagement }} engageemnt, including average {{ results.postsEngagement.averageUps }} upvotes and average {{ results.postsEngagement.averageComments }} comments per post.
          </P>
        </div>
      </div>
    </div>
    <div v-else>
      <p class="text-center">This Subreddit Does Not Exist.</p>
    </div>
    <FaqSubredditStats />

  </template>
  
  <script>
  import SubAboutView from './subAboutView.vue';
  import SubRulesView from './subRulesView.vue';
  import PostsView from './postsView.vue';
  import TopContributors from './TopContributors.vue';
  import PostsPerDay from './PostsPerDay.vue';
  import AverageEngagement from './AverageEngagement.vue';
  import FaqSubredditStats from './FAQs.vue';
  
  export default {
    name: 'SubredditStatsTab',
    components: {
      SubAboutView,
      SubRulesView,
      PostsView,
      TopContributors,
      PostsPerDay,
      AverageEngagement,
      FaqSubredditStats,
    },
    props: ['results'],
  };
  </script>
  
  <style scoped>
  /* Add any specific styling if required */
  </style>