<template>
    <div class="heatmap-section mt-4">
      <h3 class="mb-4">User Activity Heatmap</h3>
      <apexchart
        type="heatmap"
        :options="chartOptions"
        :series="series"
      ></apexchart>
    </div>
  </template>
  
  <script>
  import VueApexCharts from "vue3-apexcharts";
  
  export default {
    components: {
      apexchart: VueApexCharts,
    },
    props: {
      heatmapData: {
        type: Array,
        default: () => [], // Default to an empty array
      },
    },
    data() {
      return {
        series: this.heatmapData,
        chartOptions: {
          chart: {
            height: 350,
            type: "heatmap",
          },
          plotOptions: {
            heatmap: {
              shadeIntensity: 0.5,
              colorScale: {
                ranges: [
                  { from: 0, to: 1, color: "#E7F3FF" }, // Light blue (almost white, subtle)
                  { from: 2, to: 5, color: "#AEDFF7" }, // Bright blue
                  { from: 6, to: 10, color: "#3B82F6" }, // Facebook blue (bright primary)
                  { from: 11, to: 50, color: "#2563EB" }, // Vibrant medium-dark blue
                  { from: 51, to: 100, color: "#1E3A8A" }, // Dark deep blue
                ],
              },
            },
          },
          xaxis: {
            type: "category",
          },
        },
      };
    },
    watch: {
      heatmapData: {
        immediate: true,
        handler(newVal) {
          this.series = newVal || [];
        },
      },
    },
    mounted() {
      this.series = this.heatmapData || [];
    },
  };
  </script>

  <style scoped>
  .heatmap-section {
    padding: 20px;
  background: #f8f9fa;
  border-radius: 8px;
  font-size: 16px;
  }
</style>