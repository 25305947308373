<template>
  <div>
    <!-- Subreddit Cards Section -->
    <div v-if="ExploreSubreddits.length" class="row row-cols-1 row-cols-md-3 g-4 text-center">
      <div v-for="subreddit in ExploreSubreddits" :key="subreddit.displayName" class="col">
        <div class="card h-100 p-4">
          
          <!-- Subreddit Icon (If available) -->
          <div v-if="subreddit.icon !== 'N/A'" class="card-icon mb-auto">
            <img :src="subreddit.icon" alt="Subreddit Icon" class="rounded-circle" width="50" height="50" />
            <p class="mt-3"><strong>{{ subreddit.displayName }}</strong></p>
          </div>
          <div v-else>
            <p class="mt-3" style="color: #B33000;"><strong>{{ subreddit.displayName }}</strong></p>
          </div>
          
          <p>{{ truncatedDescription(subreddit.description) }}</p>
          <!-- Fix section to the bottom of the card -->
          <div class="mt-auto">
            <p><strong>{{ subreddit.subscribers }}</strong> Members</p>
            <a :href="'https://www.reddit.com' + subreddit.url" target="_blank" class="btn btn-outline-dark w-100">Visit</a>
          </div>
        </div>
      </div>
    </div>

    <!-- Descriptive Section -->
    <div v-if="ExploreSubreddits.length" class="mt-5">
      <h3>Most Relevant Subreddits About {{ searchQuery }}</h3>
      <p>
        Here are the {{ ExploreSubreddits.length }} most relevant subreddits related to {{ searchQuery }}:
      </p>
      <ul class="list-unstyled">
        <li v-for="(subreddit, index) in ExploreSubreddits" :key="'desc-' + index">
          <strong>{{ index + 1 }}. {{ subreddit.displayName }}</strong>: {{ subreddit.subscribers }} subscribers
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "ExploreSubreddits",
  props: {
    ExploreSubreddits: {
      type: Array,
      required: true,
    },
    searchQuery: {
      type: String,
      default: "meme", // Default search query (can be overridden by parent)
    },
  },
  methods: {
    truncatedDescription(description) {
      // Check if the description length is more than 70 characters
      if (description && description.length > 70) {
        return description.slice(0, 70) + '...'; // Truncate and add "..."
      }
      return description; // If it's 70 characters or less, return as is
    },
  },
};
</script>