import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import { createApp } from 'vue';
import App from './App.vue';
import { createHead } from '@vueuse/head';
import './assets/styles/custom.scss'; // Import custom styles
import VueApexCharts from 'vue3-apexcharts';
import router from './router';

const app = createApp(App); // Create the app instance

const head = createHead();
app.use(head); // Use vueuse/head

app.use(router); // Use the router
app.use(VueApexCharts); // Register Vue ApexCharts globally

app.mount('#app'); // Mount the app