<template>
    <div class="container mt-5">
      <!-- Heading -->
      <h2 class="text-center mb-4">FAQs About Analyzing Reddit Profiles</h2>
  
      <!-- Accordion -->
      <div class="accordion" id="analyzeProfileFAQ">
        <!-- FAQ 1 -->
        <div class="accordion-item">
          <h2 class="accordion-header" id="faqHeading1">
            <button
              class="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#faqCollapse1"
              aria-expanded="true"
              aria-controls="faqCollapse1"
            >
              What is a Reddit user analyzer?
            </button>
          </h2>
          <div
            id="faqCollapse1"
            class="accordion-collapse collapse show"
            aria-labelledby="faqHeading1"
            data-bs-parent="#analyzeProfileFAQ"
          >
            <div class="accordion-body">
              A Reddit user analyzer is a tool designed to provide detailed insights about a Reddit account. With our Reddit user analyzer, you can view stats like account age, total karma, posts, comments, and activity heatmaps.
            </div>
          </div>
        </div>
  
        <!-- FAQ 2 -->
        <div class="accordion-item">
          <h2 class="accordion-header" id="faqHeading2">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#faqCollapse2"
              aria-expanded="false"
              aria-controls="faqCollapse2"
            >
              How does Reddit account analysis work?
            </button>
          </h2>
          <div
            id="faqCollapse2"
            class="accordion-collapse collapse"
            aria-labelledby="faqHeading2"
            data-bs-parent="#analyzeProfileFAQ"
          >
            <div class="accordion-body">
              Reddit account analysis works by fetching public data from Reddit using its API. This allows us to showcase detailed Reddit user stats, including their most active subreddits and frequently used keywords.
            </div>
          </div>
        </div>
  
        <!-- FAQ 3 -->
        <div class="accordion-item">
          <h2 class="accordion-header" id="faqHeading3">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#faqCollapse3"
              aria-expanded="false"
              aria-controls="faqCollapse3"
            >
              Can I analyze any Reddit profile?
            </button>
          </h2>
          <div
            id="faqCollapse3"
            class="accordion-collapse collapse"
            aria-labelledby="faqHeading3"
            data-bs-parent="#analyzeProfileFAQ"
          >
            <div class="accordion-body">
              Yes, as long as the Reddit user’s account is public, you can use our Reddit user search feature to access their activity stats. Private accounts or deleted profiles cannot be analyzed.
            </div>
          </div>
        </div>
  
        <!-- FAQ 4 -->
        <div class="accordion-item">
          <h2 class="accordion-header" id="faqHeading4">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#faqCollapse4"
              aria-expanded="false"
              aria-controls="faqCollapse4"
            >
              What kind of Reddit user stats can I view?
            </button>
          </h2>
          <div
            id="faqCollapse4"
            class="accordion-collapse collapse"
            aria-labelledby="faqHeading4"
            data-bs-parent="#analyzeProfileFAQ"
          >
            <div class="accordion-body">
              You can view stats like:  
              <ul>
                <li>Total Karma (Posts Karma and Comments Karma)</li>
                <li>Top subreddits for posting and commenting</li>
                <li>Activity heatmaps for posts and comments</li>
                <li>Frequently used keywords</li>
                <li>A detailed breakdown of Reddit analytics for any profile</li>
              </ul>
            </div>
          </div>
        </div>
  
        <!-- FAQ 5 -->
        <div class="accordion-item">
          <h2 class="accordion-header" id="faqHeading5">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#faqCollapse5"
              aria-expanded="false"
              aria-controls="faqCollapse5"
            >
              Is this tool a Reddit profile viewer?
            </button>
          </h2>
          <div
            id="faqCollapse5"
            class="accordion-collapse collapse"
            aria-labelledby="faqHeading5"
            data-bs-parent="#analyzeProfileFAQ"
          >
            <div class="accordion-body">
              Yes, this doubles as a Reddit profile viewer, letting you explore Reddit user info, activity, and engagement across various subreddits.
            </div>
          </div>
        </div>
  
        <!-- FAQ 6 -->
        <div class="accordion-item">
          <h2 class="accordion-header" id="faqHeading6">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#faqCollapse6"
              aria-expanded="false"
              aria-controls="faqCollapse6"
            >
              How accurate is Reddinfo’s Reddit analytics?
            </button>
          </h2>
          <div
            id="faqCollapse6"
            class="accordion-collapse collapse"
            aria-labelledby="faqHeading6"
            data-bs-parent="#analyzeProfileFAQ"
          >
            <div class="accordion-body">
              We use Reddit’s official API, ensuring accurate and up-to-date information about user activity, subreddit stats, and engagement trends.
            </div>
          </div>
        </div>
  
        <!-- FAQ 7 -->
        <!-- <div class="accordion-item">
          <h2 class="accordion-header" id="faqHeading7">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#faqCollapse7"
              aria-expanded="false"
              aria-controls="faqCollapse7"
            >
              Can I download Reddit user stats?
            </button>
          </h2>
          <div
            id="faqCollapse7"
            class="accordion-collapse collapse"
            aria-labelledby="faqHeading7"
            data-bs-parent="#analyzeProfileFAQ"
          >
            <div class="accordion-body">
              Absolutely! You can download Reddit user stats as CSV files, including details like post titles, descriptions, links, comment content, and timestamps. This feature is perfect for deeper Reddit user analysis.
            </div>
          </div>
        </div> -->
  
        <!-- FAQ 8 -->
        <div class="accordion-item">
          <h2 class="accordion-header" id="faqHeading8">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#faqCollapse8"
              aria-expanded="false"
              aria-controls="faqCollapse8"
            >
              What makes Reddinfo the best Reddit analyzer?
            </button>
          </h2>
          <div
            id="faqCollapse8"
            class="accordion-collapse collapse"
            aria-labelledby="faqHeading8"
            data-bs-parent="#analyzeProfileFAQ"
          >
            <div class="accordion-body">
              It provides a comprehensive Reddit account analysis, combining subreddit stats, user activity heatmaps, and keyword usage. It’s a one-stop Reddit analytics tool for users who want to explore profiles in depth.
            </div>
          </div>
        </div>
  
        <!-- FAQ 9 -->
        <div class="accordion-item">
          <h2 class="accordion-header" id="faqHeading9">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#faqCollapse9"
              aria-expanded="false"
              aria-controls="faqCollapse9"
            >
              Is this tool free to use?
            </button>
          </h2>
          <div
            id="faqCollapse9"
            class="accordion-collapse collapse"
            aria-labelledby="faqHeading9"
            data-bs-parent="#analyzeProfileFAQ"
          >
            <div class="accordion-body">
              It offers free access to essential features like Reddit user stats and subreddit exploration. Additional premium features may be available for advanced Reddit analytics.
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "AnalyzeProfileFAQ",
  };
  </script>
