<template>
    <div class="container mt-5">
      <h2 class="text-center mb-4">FAQ About Subreddit Stats</h2>
      <div class="accordion" id="faqAccordion">
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingOne">
            <button
              class="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              What are subreddit stats?
            </button>
          </h2>
          <div
            id="collapseOne"
            class="accordion-collapse collapse show"
            aria-labelledby="headingOne"
            data-bs-parent="#faqAccordion"
          >
            <div class="accordion-body">
              It provide detailed insights into the activity and engagement of a subreddit. These statistics include metrics such as the number of members, active users, and average engagement per post.
            </div>
          </div>
        </div>
  
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              Why are subreddit stats important?
            </button>
          </h2>
          <div
            id="collapseTwo"
            class="accordion-collapse collapse"
            aria-labelledby="headingTwo"
            data-bs-parent="#faqAccordion"
          >
            <div class="accordion-body">
              It help users understand the popularity and activity level of a subreddit. This is useful for creators, marketers, and community managers who want to engage with active communities.
            </div>
          </div>
        </div>
  
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingThree">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              How do you calculate average engagement?
            </button>
          </h2>
          <div
            id="collapseThree"
            class="accordion-collapse collapse"
            aria-labelledby="headingThree"
            data-bs-parent="#faqAccordion"
          >
            <div class="accordion-body">
              Average engagement is calculated by combining the average number of upvotes and comments per post. This provides a clear measure of how users interact with the subreddit.
            </div>
          </div>
        </div>
  
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingFour">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFour"
              aria-expanded="false"
              aria-controls="collapseFour"
            >
              Can I see analytics for any subreddit?
            </button>
          </h2>
          <div
            id="collapseFour"
            class="accordion-collapse collapse"
            aria-labelledby="headingFour"
            data-bs-parent="#faqAccordion"
          >
            <div class="accordion-body">
              Yes, you can access subreddit analytics for almost any community on Reddit. Simply search for the subreddit name to view its detailed insights.
            </div>
          </div>
        </div>
  
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingFive">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFive"
              aria-expanded="false"
              aria-controls="collapseFive"
            >
              What do active members mean?
            </button>
          </h2>
          <div
            id="collapseFive"
            class="accordion-collapse collapse"
            aria-labelledby="headingFive"
            data-bs-parent="#faqAccordion"
          >
            <div class="accordion-body">
              Active members represent the users currently engaging with the subreddit, such as viewing posts, commenting, or voting. It indicates the real-time activity in the community.
            </div>
          </div>
        </div>
  
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingSix">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseSix"
              aria-expanded="false"
              aria-controls="collapseSix"
            >
              How can it help identify top contributors?
            </button>
          </h2>
          <div
            id="collapseSix"
            class="accordion-collapse collapse"
            aria-labelledby="headingSix"
            data-bs-parent="#faqAccordion"
          >
            <div class="accordion-body">
              It often include a list of top contributors ranked by their account karma or post engagement, helping identify valuable contributors in the community.
            </div>
          </div>
        </div>
  
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingSeven">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseSeven"
              aria-expanded="false"
              aria-controls="collapseSeven"
            >
              Where can I find the top posts?
            </button>
          </h2>
          <div
            id="collapseSeven"
            class="accordion-collapse collapse"
            aria-labelledby="headingSeven"
            data-bs-parent="#faqAccordion"
          >
            <div class="accordion-body">
              The top posts are typically ranked by upvotes and comments, showcasing the most popular and engaging content.
            </div>
          </div>
        </div>
  
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingEight">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseEight"
              aria-expanded="false"
              aria-controls="collapseEight"
            >
              Does it include historical trends?
            </button>
          </h2>
          <div
            id="collapseEight"
            class="accordion-collapse collapse"
            aria-labelledby="headingEight"
            data-bs-parent="#faqAccordion"
          >
            <div class="accordion-body">
              Yes, Reddinfo display historical trends such as posts-per-day graphs and engagement over time for deeper insights into subreddit activity patterns.
            </div>
          </div>
        </div>
  
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingNine">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseNine"
              aria-expanded="false"
              aria-controls="collapseNine"
            >
              How frequently are subreddit stats updated?
            </button>
          </h2>
          <div
            id="collapseNine"
            class="accordion-collapse collapse"
            aria-labelledby="headingNine"
            data-bs-parent="#faqAccordion"
          >
            <div class="accordion-body">
              They are updated in real-time or periodically, depending on the tool you use. Tools like Reddinfo provide accurate and up-to-date statistics for users.
            </div>
          </div>
        </div>
  
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTen">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTen"
              aria-expanded="false"
              aria-controls="collapseTen"
            >
              How can I use stats for content strategy?
            </button>
          </h2>
          <div
            id="collapseTen"
            class="accordion-collapse collapse"
            aria-labelledby="headingTen"
            data-bs-parent="#faqAccordion"
          >
            <div class="accordion-body">
              They help identify content that resonates with the audience. By analyzing top posts and engagement metrics, you can create content that aligns with audience preferences.
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "FaqSubredditStats",
  };
  </script>