<template>
  <div class="stats-table">
    <h3 class="mb-4">User Activity Stats</h3>

    <!-- Total Posts and Comments -->
    <table>
      <thead>
        <tr>
          <th>Total Posts</th>
          <th>Total Comments</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ userActivity.totalPosts }}</td>
          <td>{{ userActivity.totalComments }}</td>
        </tr>
      </tbody>
    </table>

    <!-- Subreddit Tables -->
    <div class="row">
      <!-- Top Post Subreddits -->
      <div class="col-md-6 mt-5">
        <h4>Top Post Subreddits</h4>
        <table
          v-if="userActivity.topSubredditsPosts && userActivity.topSubredditsPosts.length"
          class="subreddit-table"
        >
          <thead>
            <tr>
              <th>Subreddit</th>
              <th>Number of Posts</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="subreddit in userActivity.topSubredditsPosts"
              :key="subreddit.subreddit"
            >
              <td>{{ subreddit.subreddit }}</td>
              <td>{{ subreddit.count }}</td>
            </tr>
          </tbody>
        </table>
        <p v-else class="no-data">No active subreddits found.</p>
      </div>

      <!-- Top Comment Subreddits -->
      <div class="col-md-6 mt-5">
        <h4>Top Comment Subreddits</h4>
        <table
          v-if="userActivity.topSubredditsComments && userActivity.topSubredditsComments.length"
          class="subreddit-table"
        >
          <thead>
            <tr>
              <th>Subreddit</th>
              <th>Number of Comments</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="subreddit in userActivity.topSubredditsComments"
              :key="subreddit.subreddit"
            >
              <td>{{ subreddit.subreddit }}</td>
              <td>{{ subreddit.count }}</td>
            </tr>
          </tbody>
        </table>
        <p v-else class="no-data">No active subreddits found.</p>
      </div>
    </div>

    <!-- Export Button and Link -->

    <!-- <div class="container mt-5">
      <div class="d-flex flex-column align-items-center">
        <button class="btn btn-primary mb-3" @click="exportPosts">
          Export User Posts & Comments for $4
        </button>
        <div class="text-center mb-2">
          <h6>
            You will get <strong>{{ userActivity.totalPosts }}</strong> User Posts and
            <strong>{{ userActivity.totalComments }}</strong> Comments
          </h6>
        </div> -->

        <!-- CSV Details -->
        <!-- <div class="text-start mt-4">
          <h5>CSVs include:</h5>
        </div>
        <div class="row"> -->
          <!-- Posts Data -->
          <!-- <div class="col-md-6">
            <ul class="text-start">
              <li>Post Title</li>
              <li>Post Description</li>
              <li>Post Link</li>
              <li>Subreddit Name</li>
              <li>Subreddit Link</li>
              <li>Post Created Date</li>
            </ul>
          </div> -->
          <!-- Comments Data -->
          <!-- <div class="col-md-6">
            <ul class="text-start">
              <li>Comment Content</li>
              <li>Comment Link</li>
              <li>Subreddit Name</li>
              <li>Subreddit Link</li>
              <li>Comment Posted Date</li>
            </ul>
          </div> -->
        <!-- </div> -->
      <!-- </div> -->
    <!-- </div> -->
  </div>
</template>

<script>
export default {
  name: "UserActivityStats",
  props: {
    userActivity: {
      type: Object,
      required: true,
    },
  },
  methods: {
    exportPosts() {
      const exportUrl = `https://export.reddinfo.com?posts=${this.userActivity.totalPosts}`;
      window.open(exportUrl, "_blank");
    },
  },
};
</script>

<style scoped>
.stats-table {
  padding: 20px;
  background: #f8f9fa;
  border-radius: 8px;
  font-size: 16px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

th,
td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ccc;
}

.subreddit-table {
  margin-top: 10px;
}

.no-data {
  color: #999;
  font-style: italic;
}

ul {
  list-style-type: disc;
  margin-left: 20px;
}

li {
  margin-bottom: 5px;
}
</style>