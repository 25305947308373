/* global gtag */
export async function fetchUserStats(username) {
  try {
    // Google Analytics Event Tracking
    if (typeof gtag === "function") {
      gtag("event", "fetched_user_stats", {
        search_term: username,
        event_category: "engagement",
        event_label: "Fetch User Stats Triggered",
        value: 1,
      });
    }

    const response = await fetch(`https://www.reddit.com/user/${username}/about.json`);
    
    // Check if the response is okay
    if (!response.ok) {

      return { userFound: false };
    }

    const data = await response.json();

    // Truncate the icon_img URL before the "?"
    const iconImg = data.data.icon_img ? data.data.icon_img.split("?")[0] : null;

    return {
      userFound: true,
      icon: iconImg,
      name: data.data.name,
      karma: data.data.total_karma,
      created: formatAccountAge(data.data.created_utc),
      postsKarma: data.data.link_karma,
      commentsKarma: data.data.comment_karma,
    };
  } catch (error) {
    return { userFound: false };
  }
}

function formatAccountAge(timestamp) {
  const accountDate = new Date(timestamp * 1000); // Convert Unix timestamp to milliseconds
  const currentDate = new Date();

  let years = currentDate.getFullYear() - accountDate.getFullYear();
  let months = currentDate.getMonth() - accountDate.getMonth();

  // Adjust for negative months (e.g., account created in December and current month is January)
  if (months < 0) {
    years -= 1; // Subtract 1 year
    months += 12; // Add 12 months to the difference
  }

  return `${years} years, ${months} months`;
}