const API_BASE = 'https://www.reddit.com/user';

export async function fetchUserActivity(username) {
  try {
    const fetchAllData = async (url) => {
      let allData = [];
      let after = null;
      do {
        const response = await fetch(url + (after ? `&after=${after}` : ''));
        const data = await response.json();
        allData = [...allData, ...data.data.children.map((item) => item.data)];
        after = data.data.after;
      } while (after); // Exit the loop when there's no 'after' value
      return allData;
    };

    // Fetch posts and comments with pagination handling
    const posts = await fetchAllData(`${API_BASE}/${username}/submitted.json?limit=100`);
    const comments = await fetchAllData(`${API_BASE}/${username}/comments.json?limit=100`);

    if (!posts || !comments) {
      throw new Error('Failed to fetch posts or comments');
    }

    return { posts, comments };
  } catch (error) {
    console.error("Error fetching user activity:", error);
    return { posts: [], comments: [] }; // Return empty arrays on error
  }
}

export function processUserActivity(posts, comments) {
  // Check if posts and comments are arrays
  if (!Array.isArray(posts) || !Array.isArray(comments)) {
    return null;
  }

  const activity = {
    totalPosts: posts.length,
    totalComments: comments.length,
  };

  // Get the top subreddits separately for posts and comments
  const topSubredditsPosts = posts.length > 0 ? getTopSubreddits(posts) : [];
  const topSubredditsComments = comments.length > 0 ? getTopSubreddits(comments) : [];
  const wordFrequency = generateWordFrequency(posts);

  return { ...activity, topSubredditsPosts, topSubredditsComments, wordFrequency };
}

// Example implementation of getTopSubreddits function
function getTopSubreddits(items) {
  const subredditCount = items.reduce((acc, item) => {
    acc[item.subreddit] = (acc[item.subreddit] || 0) + 1;
    return acc;
  }, {});

  return Object.entries(subredditCount)
    .map(([subreddit, count]) => ({ subreddit, count }))
    .sort((a, b) => b.count - a.count)
    .slice(0, 5); // Return top 5 subreddits
}

export function generateHeatmapData(posts, comments) {
  if (!posts || !comments) {
    console.error('Posts or comments data is missing');
    return [];
  }

  const allActivity = [...posts, ...comments];


  // Group activity by weekday and hour
  const heatmapData = Array.from({ length: 7 }, () =>
    Array.from({ length: 24 }, () => 0)
  );

  allActivity.forEach((item) => {
    const date = new Date(item.created_utc * 1000);
    const day = date.getDay(); // 0 = Sunday, 6 = Saturday
    const hour = date.getHours();
    heatmapData[day][hour]++;
  });

  // Format for ApexCharts
  return heatmapData.map((data, day) => ({
    name: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"][day],
    data: data.map((value, hour) => ({ x: `${hour}:00`, y: value })),
  }));
}

  // Calculating words frequency

  // Extract text from posts
  function extractTextFromPosts(posts) {
    return posts.map(post => {
      return (post.title + ' ' + post.selftext).toLowerCase();
    }).join(' ');
  }

  // Preprocess the text (remove stopwords, non-alphanumeric characters)
  function preprocessText(text) {
    const stopwords = [
      'a', 'about', 'above', 'after', 'again', 'against', 'all', 'am', 'an', 'and', 'any', 'are', 'aren’t', 
      'as', 'at', 'be', 'because', 'been', 'before', 'being', 'below', 'between', 'both', 'but', 'by', 
      'can', 'cannot', 'could', 'couldn’t', 'did', 'didn’t', 'do', 'does', 'doesn’t', 'doing', 'don’t', 
      'down', 'during', 'each', 'few', 'for', 'from', 'further', 'had', 'hadn’t', 'has', 'hasn’t', 
      'have', 'haven’t', 'having', 'he', 'he’d', 'he’ll', 'he’s', 'her', 'here', 'here’s', 'hers', 
      'herself', 'him', 'himself', 'his', 'how', 'how’s', 'i', 'i’d', 'i’ll', 'i’m', 'i’ve', 'if', 
      'in', 'into', 'is', 'isn’t', 'it', 'it’s', 'its', 'itself', 'let’s', 'me', 'more', 'most', 
      'mustn’t', 'my', 'myself', 'no', 'nor', 'not', 'of', 'off', 'on', 'once', 'only', 'or', 'other', 
      'ought', 'our', 'ours', 'ourselves', 'out', 'over', 'own', 'same', 'shan’t', 'she', 'she’d', 
      'she’ll', 'she’s', 'should', 'shouldn’t', 'so', 'some', 'such', 'than', 'that', 'that’s', 'the', 
      'their', 'theirs', 'them', 'themselves', 'then', 'there', 'there’s', 'these', 'they', 'they’d', 
      'they’ll', 'they’re', 'they’ve', 'this', 'those', 'through', 'to', 'too', 'under', 'until', 'up', 
      'very', 'was', 'wasn’t', 'we', 'we’d', 'we’ll', 'we’re', 'we’ve', 'were', 'weren’t', 'what', 
      'what’s', 'when', 'when’s', 'where', 'where’s', 'which', 'while', 'who', 'who’s', 'whom', 'why', 
      'why’s', 'with', 'won’t', 'would', 'wouldn’t', 'you', 'you’d', 'you’ll', 'you’re', 'you’ve', 'your', 
      'yours', 'yourself', 'yourselves', 'like', 'just', 'get', 'got', 'know', 'one', 'people', 'think','every','quickly'
    ];
    return text
      .replace(/[^\w\s]/g, '') // Remove non-word characters
      .split(' ') // Split into words
      .filter(word => !stopwords.includes(word) && word.length > 1) // Remove stopwords and short words
      .join(' ');
  }

  function generateWordFrequency(posts) {
    const allText = extractTextFromPosts(posts);
    const processedText = preprocessText(allText);
  
    const words = processedText.split(' ');
    const frequency = {};
  
    words.forEach(word => {
      frequency[word] = (frequency[word] || 0) + 1;
    });
  
    // Convert frequency object to an array of objects with `text` and `weight`
    const wordFrequencyArray = Object.entries(frequency).map(([word, count]) => ({
      text: word,
      weight: count,
    }));
  
    return wordFrequencyArray; // Return array of objects
  }




// function copyPostsToClipboard(posts) {
//   const formattedData = posts.map(post => {
//     const title = post.title || ""; // Post title
//     const description = (post.selftext || "").replace(/\r?\n|\r/g, ' '); // Post description with line breaks replaced
//     const upvotes = post.ups || 0; // Post upvotes
//     const comments = post.num_comments || 0; // Post comments
//     const subredditName = post.subreddit || ""; // Subreddit name
//     const subredditLink = `https://www.reddit.com/r/${subredditName}`; // Subreddit hyperlink
//     const permalink = `https://www.reddit.com${post.permalink}`; // Post link

//     // Format each row
//     return `"${title}","${description}",${upvotes},${comments},"${permalink}",${subredditName},"${subredditLink}"`;
//   });

//   // Join rows with a newline to create a CSV-like format
//   const csvContent = formattedData.join('\n');

//   // Copy the data to the clipboard
//   navigator.clipboard.writeText(csvContent).then(() => {
//     console.log("Posts data copied to clipboard!");
//   }).catch(err => {
//     console.error("Failed to copy posts data to clipboard:", err);
//   });
// }
