export async function fetchPopSubreddits() {
    try {
      const response = await fetch('https://www.reddit.com/subreddits/popular.json?limit=9');
      const data = await response.json();
  
      // Extract relevant subreddit details and format the subscribers
      const topSubreddits = data.data.children.map(item => ({
        displayName: item.data.display_name,
        title: item.data.title,
        description: item.data.public_description || 'No description available.',
        subscribers: item.data.subscribers ? new Intl.NumberFormat().format(item.data.subscribers) : '0',  // Format subscribers with commas
        icon: item.data.icon_img || 'N/A',
        url: item.data.url
      }));
  
      // Sort the subreddits by subscribers in descending order
      topSubreddits.sort((a, b) => 
        parseInt(b.subscribers.replace(/,/g, '')) - parseInt(a.subscribers.replace(/,/g, ''))
      );
      return topSubreddits;  // Return sorted subreddits data
    } catch (error) {
      console.error('Error fetching top subreddits:', error);
      return [];  // Return an empty array if an error occurs
    }
  }